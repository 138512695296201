import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { IdentityService } from 'src/app/core/services/identity.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(
        private router: Router,
        private api: ApiService,
        private $identity: IdentityService,
    ) { }

    getAccount() {
        return this.api.get(`_base/api/v1.0/AdminAccount`)
            .pipe(
                map((response: any) => {
                    if (response.result) {
                        return response.result;
                    }

                    return response;
                })
            );
    }

    signIn(data) {
        return this.api.post(`_base/api/v1.0/AdminAccount/SignIn`, data)
            .pipe(
                map((response: any) => {
                    if (response.result) {
                        this.$identity.insert(response.result);
                    }

                    return response;
                })
            );
    }

    refreshToken(refreshToken): Observable<any> {
        return this.api.post(`_base/api/v1.0/AdminAccount/RefreshToken`, { refreshToken });
    }

    checkRefresh() {
        if (this.$identity.data.expiresIn) {
            const now = moment.utc();
            const expire = moment.utc(this.$identity.data.expiresIn);
            if (now.isAfter(expire)) {
                this.refreshToken(this.$identity.data.refreshToken)
                    .subscribe((newSession: any) => {
                        if (newSession) {
                            this.$identity.insert(newSession.result);
                        } else {
                            this.$identity.clear();
                            this.router.navigate(['/auth/sign-in']);
                        }
                    });
            }
        }
    }

    loadImage(file: FormData) {
        return this.api.uploadFile(`_base/api/v1.0/File/Image`, file)
            .pipe(
                map((response) => {
                    return response.result;
                })
            );
    }
}
