export const Errors = {
  1: {
    en: 'Token Expired'
  },

  2: {
    en: 'Authorization Failed'
  },

  3: {
    en: 'Invalid Credentials'
  },

  4: {
    en: 'Permission Denied'
  },

  5: {
    en: 'NoAuthorization'
  },

  6: {
    en: 'UnsupportedLoginProvider'
  },

  7: {
    en: 'CouldNotCreateUser'
  },

  8: {
    en: 'UnconfirmedUser'
  },

  11: {
    en: 'InvalidOauthToken'
  },

  12: {
    en: 'RefreshTokenIsIncorrect'
  },

  13: {
    en: 'Refresh Token Does Not Exist'
  },

  14: {
    en: 'UserIsBlocked'
  },

  15: {
    en: 'CouldNotGetSocialAccount'
  },

  16: {
    en: 'InvalidExternalLoginInfo'
  },

  50: {
    en: 'Unknown Error'
  },

  51: {
    en: 'DbExceptionError'
  },

  52: {
    en: 'DbEntityNotFound'
  },

  56: {
    en: 'CouldNotSavePhoto'
  },

  58: {
    en: 'MismatchOldPassword'
  },

  59: {
    en: 'EmailSendError'
  },

  60: {
    en: 'ViewRenderError'
  },

  61: {
    en: 'HealthCheckNotHealthy'
  },

  100: {
    en: 'InvalidModel'
  },

  101: {
    en: 'ConfirmCodeDoesNotExist'
  },

  102: {
    en: 'InvalidConfirmCode'
  },

  103: {
    en: 'UserDoesNotExist'
  },

  104: {
    en: 'CouldNotUpdateUser'
  },

  105: {
    en: 'InvalidOperation'
  },

  106: {
    en: 'Email Already Taken'
  },

  107: {
    en: 'Invalid Email'
  },

  108: {
    en: 'EnumDoesNotHasAnyValues'
  },

  109: {
    en: 'EnumSumIsNotValid'
  },

  110: {
    en: 'IncorrectEnumValue'
  },

  111: {
    en: 'InvalidBirthday'
  },

  112: {
    en: 'ConfirmCodeIsExpired'
  },

  113: {
    en: 'CouldNotSendCodeOnConfirmedEmail'
  },

  114: {
    en: 'UserWithSameDataAlreadyRegistered'
  },

  115: {
    en: 'Phone Is Confirmed'
  },

  116: {
    en: 'Phone Already Taken'
  },

  117: {
    en: 'Email Is Not Confirmed'
  },

  118: {
    en: 'Email Is Confirmed'
  },

  119: {
    en: 'DomainNotDetected'
  },

  120: {
    en: 'Confirm Code Used Already'
  },

  121: {
    en: 'SocialIdAlreadyTaken'
  },

  300: {
    en: 'Session Does Not Exist'
  },

  301: {
    en: 'Session Expired'
  },

  350: {
    en: 'FileNotSelected'
  },

  351: {
    en: 'BigFile'
  },

  352: {
    en: 'HeavyFile'
  },

  353: {
    en: 'IncorrectFileType'
  },

  354: {
    en: 'SmallFile'
  },

  355: {
    en: 'CouldNotUploadFile'
  },

  356: {
    en: 'CouldNotDownloadFile'
  },

  357: {
    en: 'CouldNotDeleteFile'
  },

  358: {
    en: 'CouldNotMoveFile'
  },

  359: {
    en: 'FileNotExist'
  },

  400: {
    en: '//DefaultError'
  },

  401: {
    en: '//ConcurrencyFailure'
  },

  402: {
    en: 'PasswordMismatch'
  },

  403: {
    en: 'InvalidToken'
  },

  404: {
    en: 'RecoveryCodeRedemptionFailed'
  },

  405: {
    en: 'LoginAlreadyAssociated'
  },

  406: {
    en: 'InvalidUserName'
  },

  407: {
    en: '//InvalidEmail'
  },

  408: {
    en: '//DuplicateUserName'
  },

  409: {
    en: 'DuplicateEmail'
  },

  410: {
    en: '//InvalidRoleName'
  },

  411: {
    en: '//DuplicateRoleName'
  },

  412: {
    en: 'UserAlreadyHasPassword'
  },

  413: {
    en: '//UserLockoutNotEnabled'
  },

  414: {
    en: '//UserAlreadyInRole'
  },

  415: {
    en: '//UserNotInRole'
  },

  416: {
    en: 'PasswordTooShort'
  },

  417: {
    en: 'PasswordRequiresUniqueChars'
  },

  418: {
    en: 'PasswordRequiresNonAlphanumeric'
  },

  419: {
    en: 'PasswordRequiresDigit'
  },

  420: {
    en: 'PasswordRequiresLower'
  },

  421: {
    en: 'PasswordRequiresUpper'
  },

  514: {
    en: 'Sorry, but in the trial version, you are not able to remove the sales channel.'
  },

  2001: {
    en: 'Postal code is invalid or doesn`t exist'
  },

  2501: {
    en: 'ProcessingOrdersFound'
  },
  2502: {
    en: 'FulfilledInventoryWarehousesFound'
  },

  2503: {
    en: 'You can not remove Marketplace if it has publishing Listings, or pending, unshipped and partly shipped Orders.'
  },

  4002: {
    en: 'Category Already Created'
  },

  4005: {
    en: 'CategoryNotFound'
  },

  4007: {
    en: 'Child Category Can Not Become A Parent'
  },

  7001: {
    en: 'Product with this SKU is already exist'
  },

  7002: {
    en: 'InventoryWarehouseAlreadyExist'
  },

  7003: {
    en: 'InventoryWarehouseNotExist'
  },

  17002: {
    en: 'TooManyTags'
  },

  8001: {
    en: 'Marketplace Is Already Used'
  },

  8002: {
    en: 'UnsupportedMarketplace'
  },

  8003: {
    en: 'MarketplaceCredentialsExpired'
  },

  8004: {
    en: 'Bad Marketplace Credentials'
  },

  8005: {
    en: 'MarketplaceNotActive'
  },

  8006: {
    en: 'MarketplaceNotAccepted'
  },

  8007: {
    en: 'MarketplaceAlreadyAccepted'
  },

  8507: {
    en: 'QueueOverflow'
  },

  9001: {
    en: 'EmptyOrderItemsList'
  },

  9502: {
    en: 'Order cannot be canceled'
  },

  9503: {
    en: 'Order cannot be approved nor rejected'
  },

  10001: {
    en: 'PositionAlreadyCreated'
  },

  10002: {
    en: 'CannotBeModifiedDefaultPosition'
  },

  11001: {
    en: 'SupplierAlreadyCreated'
  },

  12001: {
    en: 'WarehouseAlreadyCreated'
  },

  21001: {
    en: 'ForbiddenRemovePrimaryWarehouse'
  },

  13001: {
    en: 'OrderShouldBeReadyToShip'
  },

  13002: {
    en: 'NotCorrectOrderItems'
  },

  13003: {
    en: 'ShipmentShouldBePending'
  },

  13004: {
    en: 'ShipmentShouldBeSuccess'
  },

  13005: {
    en: 'ShipmentShouldNotBePublishing'
  },

  13006: {
    en: 'ShipmentShouldNotBeCustom'
  },

  13007: {
    en: 'ShipmentShouldNotBeReturn'
  },

  13008: {
    en: 'ShipmentShouldNotBeExternal'
  },

  13009: {
    en: 'ShipmentRateNotFound'
  },

  13010: {
    en: 'Shipment Is Already Bought'
  },

  13011: {
    en: 'It is not possible to Get Rates for shipments with different parameters'
  },

  13014: {
    en: 'Ineligible Order Cannot Be Shipped'
  },

  14003: {
    en: 'Please, add one more allowed identifier (ASIN, EAN, UPS, GSID or GTN) for Amazon publishing'
  },

  18001: {
    en: 'IncorrectFileData'
  },

  18002: {
    en: 'DoesNotExistRequiredFields'
  },

  18003: {
    en: 'IncorrectValues'
  },

  20029: {
    en: 'International Shipping Not Supported'
  },

  22002: {
    en: 'CardNotFound'
  },

  23001: {
    en: 'Missing Billing Info'
  },

  23002: {
    en: 'InsufficientFunds'
  },

  24001: {
    en: 'PackageSetAlreadyCreated'
  },

  25001: {
    en: 'ListingShouldNotBePublishing'
  },

  25002: {
    en: 'ListingShouldNotBeDraft'
  },

  25004: {
    en: 'ListingAlreadyExist'
  },

  25005: {
    en: 'ListingShouldBeEdited'
  },

  25006: {
    en: 'ListingLinked'
  },

  25007: {
    en: 'InventoryLinked'
  },

  35001: {
    en: 'This feature already exists'
  },

  45001: {
    en: 'Promo like this already exists'
  },

  45002: {
    en: 'PromoCode Unavailable'
  },

  40003: {
    en: 'Promo Code Used Already'
  },
};

