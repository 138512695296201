import { Injectable } from '@angular/core';

function _window(): any {
    return window;
}

@Injectable()
export class WinRef {
    // native widow object
    get nativeWindow(): any {
        return _window();
    }
}
