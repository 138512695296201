import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  options = {
    position: ['bottom', 'right'],
    timeOut: 4000,
    showProgressBar: false,
    animate: 'fromLeft',
    theClass: 'override-notification'
  };

  constructor(
    private notify: NotificationsService,
    private translate: TranslateService
  ) { }

  success(message?: string) {
    const template = `<ng-template>
                        <div class="d-flex justify-content-between align-items-center">
                             <div class="notify-message">
                                <div class="mr-3">
                                <i class="icon-notification"></i>
                                </div>
                                <div>${message}</div>
                              </div>
                                <span>&times;</span>
                            </div>
                     </ng-template>`;

    this.notify.success('', template, this.options);
  }
  errorByTranslation(errorCode: string) {
    this.translate.get(errorCode)
      .toPromise()
      .then((errorMessage) => {
        this.error(errorMessage);
      }
      );
  }
  error(error?: any) {
    const template = `<ng-template>
                        <div class="d-flex justify-content-between align-items-center">
                             <div class="notify-message">
                                <div class="mr-3">
                                <i class="icon-notification"></i>
                                </div>
                                <div>${error}</div>
                              </div>
                                <span>&times;</span>
                            </div>
                     </ng-template>`;

    this.notify.error('', template, this.options);
  }

  warning(message?: string) {
    const template = `<ng-template>
                        <div class="d-flex justify-content-between align-items-center" style="background-color: #F5A536; color: white">
                             <div class="notify-message">
                                <div class="mr-3">
                                <i class="icon-notification"></i>
                                </div>
                                <div>${message}</div>
                              </div>
                                <span>&times;</span>
                            </div>
                     </ng-template>`;
    this.notify.html(template, NotificationType.Warn, this.options);
  }
}
