import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IdentityService } from '../services/identity.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private $identity: IdentityService,
    ) { }

    canActivate() {
        if (this.$identity.isAuthorize()) {
            return true;
        }

        this.router.navigate(['/auth/sign-in']);
        return false;
    }
}
