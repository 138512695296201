import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  loaderState = false;
  statusText: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.showLoader
      .pipe(takeUntil(this.destroy$))
      .subscribe((status?: string) => {
        if (status) {
          this.statusText = status;
        } else {
          this.statusText = 'Loading';
        }
        this.loaderState = true;
      });

    this.loaderService.hideLoader
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.statusText = '';
        this.loaderState = false;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
