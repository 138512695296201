import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    showLoader = new Subject();
    hideLoader = new Subject();

    constructor() { }

    show(status?) {
        this.showLoader.next(status);
    }

    hide() {
        this.hideLoader.next();
    }
}
