import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ServerErrorsService } from 'src/app/shared/utils/server-errors.service';

interface OptionInterface {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: string;
    params?: HttpParams | {
        [param: string]: string | string[] | boolean;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable()
export class ApiService {

    constructor(
        private router: Router,
        private http: HttpClient,
        private loaderService: LoaderService,
        private serverErrorsService: ServerErrorsService
    ) { }

    get(path: string, params: HttpParams | {} = new HttpParams()): Observable<any> {
        return this.http.get(path, { headers: this.setHeaders(), params })
            .pipe(
                catchError(this.handleError)
            );
    }

    put(path: string, body = {}): Observable<any> {
        return this.http.put(path, JSON.stringify(body), {
            headers: this.setHeaders()
        })
            .pipe(
                catchError(this.handleError)
            );
    }

    post(path: string, body = {}, options?: OptionInterface): Observable<any> {
        const opt = {
            headers: this.setHeaders()
        };

        if (options) {
            Object.assign(opt, options);
        }

        return this.http.post(path, body, opt)
            .pipe(
                catchError(this.handleError)
            );
    }

    patch(path: string, body = {}): Observable<any> {
        return this.http.patch(path, JSON.stringify(body), {
            headers: this.setHeaders()
        })
            .pipe(
                catchError(this.handleError)
            );
    }

    delete(path: string): Observable<any> {
        return this.http.delete(path, { headers: this.setHeaders() })
            .pipe(
                catchError(this.handleError)
            );
    }

    /*
    * TODO: do this method more pretty. try to combine with this.get method
    * */
    download(path: string, params: HttpParams | {} = new HttpParams()) {
        return this.http.get(path,
            {
                headers: this.setHeaders(),
                responseType: 'blob',
                params
            })
            .pipe(
                catchError(this.handleError)
            );
    }

    downloadBulk(path: string, body = {}) {
        return this.http.patch(path, JSON.stringify(body),
            {
                headers: this.setHeaders(),
                responseType: 'blob'
            })
            .pipe(
                catchError(this.handleError)
            );
    }

    uploadFile(path: string, body = {}, options?: OptionInterface): Observable<any> {
        if (options) {
            Object.assign(options);
        }

        return this.http.post(path, body)
            .pipe(
                catchError(this.handleError)
            );
    }

    setHeaders(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json'
        };

        return new HttpHeaders(headersConfig);
    }

    private handleError = (error: any) => {
        this.loaderService.hide();
        if (!(error && ((error.status === 401) || (error.error.error.code === 3)))) {

            if (!this.router.isActive('auth', false)) {
                this.serverErrorsService.getErrorMessage(error);
            }
        }


        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (error.status) {
                console.error(
                    `Backend returned code ${error.status}, ` +
                    `body was: ${error.error.error.description}`);
            }
        }
        // return an observable with a error response
        return throwError(error.error);
    }
}
