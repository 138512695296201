import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AccountService } from 'src/app/shared/services/account.service';
import { PatternService } from 'src/app/shared/services/pattern.service';

import { IdentityService } from '../services/identity.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private router: Router,
    private $identity: IdentityService,
    private accountService: AccountService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req)).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            return this.handle400Error(error);
          case 401:
            const internalErrorCode = error.error.error.code;

            if (internalErrorCode === 300 || internalErrorCode === 301) {
              return throwError(error);
            }

            return this.handle401Error(req, next);
          default:
            return throwError(error);
        }
      })
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      return this.accountService.refreshToken(this.$identity.data.refreshToken).pipe(
        catchError((error: HttpErrorResponse) => {
          this.$identity.clear();
          this.router.navigate(['/auth/sign-in']);
          this.isRefreshingToken = false;
          return throwError({ error });
        }),
        switchMap((newSession: any) => {
          if (newSession) {
            this.$identity.insert(newSession.result);
            this.tokenSubject.next(newSession.result);

            return next.handle(this.addToken(req));
          }
          this.$identity.clear();
          this.router.navigate(['/auth/sign-in']);

          return of(null); // return empty Observable
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          return next.handle(this.addToken(req));
        }));
    }
  }

  private handle400Error(error) {
    if (error && error.status === 400 && error.error.error.code === 3) {
      this.$identity.clear();
      this.router.navigate(['/auth/sign-in']);
    }

    return throwError(error);
  }

  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    const pattern = PatternService.hostReplace;
    if (this.$identity.token && req.url.match(pattern) !== null) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.$identity.token}`
        }
      });
    }
    return req;
  }
}
