import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: './views/layout/layout.module#LayoutModule',
    canActivate: [AuthGuard]
  },
  { path: 'auth', loadChildren: './views/auth/auth.module#AuthModule' },
  { path: '404', loadChildren: './views/not-found/not-found.module#NotFoundModule', canActivate: [AuthGuard] },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
