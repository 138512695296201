import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { NotifyService } from './notify.service';
import { Errors } from './server-errors';

@Injectable({
    providedIn: 'root'
})
export class ServerErrorsService {

    constructor(
        private notifyService: NotifyService
    ) { }

    getErrorMessage(error: any): void {
        let description = 'Unknown error.';
        const errorCode = error.error.error.code;
        if (_.has(Errors, errorCode)) {
            description = Errors[errorCode].en;
            this.notifyService.error(description);
        } else {
            this.notifyService.error(description);
        }
    }
}
