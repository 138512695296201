import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TokenModel } from 'src/app/shared/models/token.model';
import { WinRef } from 'src/app/shared/utils/win-ref';

const TOKEN_KEY = 'QElTvtu';

@Injectable()
export class IdentityService {
    private tokenData: TokenModel = new TokenModel();

    get data(): TokenModel {
        return this.tokenData;
    }

    get env() {
        return this.$winRef.nativeWindow.__env;
    }

    get token(): string {
        return this.tokenData.accessToken;
    }

    constructor(private $winRef: WinRef) {
        if (!this.tokenData.accessToken && localStorage.getItem(TOKEN_KEY)) {
            this.tokenData = JSON.parse(localStorage.getItem(TOKEN_KEY));
        }
    }

    public insert(model: TokenModel) {
        this.tokenData = new TokenModel();
        Object.assign(this.tokenData, model);

        localStorage.setItem(TOKEN_KEY, JSON.stringify(model));
    }

    public clear() {
        localStorage.removeItem(TOKEN_KEY);
        this.tokenData = new TokenModel();
    }

    public isAuthorize(): boolean {
        if (!this.tokenData.accessToken && localStorage.getItem(TOKEN_KEY)) {
            this.tokenData = JSON.parse(localStorage.getItem(TOKEN_KEY));
        }


        if (this.tokenData.refreshTokenExpiresIn) {
            const now = moment.utc();
            const expire = moment.utc(this.tokenData.refreshTokenExpiresIn);

            return now.isBefore(expire);
        }

        return false;
    }
}
