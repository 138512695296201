import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IdentityService } from '../services/identity.service';
import { PatternService } from 'src/app/shared/services/pattern.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private $identity: IdentityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const pattern = PatternService.hostReplace;

    const request = req.clone({
      url: req.url.replace(pattern, x => !!this.$identity.env[x] ? this.$identity.env[x] : x),
    });

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 200) {
            if (event.body.hasOwnProperty('error') && event.body.error !== null) {
              const service = req.url.match(pattern);
              event.body.error._service = !!service ? service[0] : null;
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
